const StaffIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1683_5630)">
        <path
          d="M12 12.894C13.1867 12.894 14.3467 12.5422 15.3334 11.8829C16.3201 11.2236 17.0892 10.2865 17.5433 9.19015C17.9974 8.09379 18.1162 6.88739 17.8847 5.7235C17.6532 4.55962 17.0818 3.49052 16.2426 2.6514C15.4035 1.81229 14.3344 1.24085 13.1705 1.00933C12.0067 0.777823 10.8003 0.896643 9.7039 1.35077C8.60754 1.80489 7.67047 2.57393 7.01118 3.56062C6.35189 4.54732 6 5.70736 6 6.89405C6.00159 8.48486 6.63424 10.0101 7.75911 11.1349C8.88399 12.2598 10.4092 12.8925 12 12.894ZM12 2.89405C12.7911 2.89405 13.5645 3.12864 14.2223 3.56817C14.8801 4.00769 15.3928 4.63241 15.6955 5.36331C15.9983 6.09422 16.0775 6.89848 15.9231 7.67441C15.7688 8.45033 15.3878 9.16306 14.8284 9.72247C14.269 10.2819 13.5563 10.6628 12.7804 10.8172C12.0044 10.9715 11.2002 10.8923 10.4693 10.5896C9.73836 10.2868 9.11365 9.77412 8.67412 9.11633C8.2346 8.45853 8 7.68517 8 6.89405C8 5.83318 8.42143 4.81576 9.17157 4.06562C9.92172 3.31547 10.9391 2.89405 12 2.89405Z"
          fill="white"
        />
        <path
          d="M12 14.8945C9.61386 14.8972 7.32622 15.8462 5.63896 17.5335C3.95171 19.2207 3.00265 21.5084 3 23.8945C3 24.1597 3.10536 24.4141 3.29289 24.6016C3.48043 24.7892 3.73478 24.8945 4 24.8945C4.26522 24.8945 4.51957 24.7892 4.70711 24.6016C4.89464 24.4141 5 24.1597 5 23.8945C5 22.038 5.7375 20.2575 7.05025 18.9448C8.36301 17.632 10.1435 16.8945 12 16.8945C13.8565 16.8945 15.637 17.632 16.9497 18.9448C18.2625 20.2575 19 22.038 19 23.8945C19 24.1597 19.1054 24.4141 19.2929 24.6016C19.4804 24.7892 19.7348 24.8945 20 24.8945C20.2652 24.8945 20.5196 24.7892 20.7071 24.6016C20.8946 24.4141 21 24.1597 21 23.8945C20.9974 21.5084 20.0483 19.2207 18.361 17.5335C16.6738 15.8462 14.3861 14.8972 12 14.8945Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1683_5630">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.894043)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StaffIcon;
