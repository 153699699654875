import { IS_MOBILE, PORTALS } from "@constants/variables";
import {
  ApplicationAction,
  ApplicationContextProps,
  ApplicationState,
} from "@interface/application.interface";
import { Modal } from "antd";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

const applicationInitState = {
  portal: PORTALS.ROOT,
  version: "",
};

const applicationReducer = (
  state: ApplicationState,
  _action: ApplicationAction
): ApplicationState => {
  return state;
};

export const ApplicationContext = createContext<ApplicationContextProps>({
  state: applicationInitState,
  isMobileLayout: false,
  dispatch: () => {},
});

const ApplicationProvider = ({ children }: React.PropsWithChildren) => {
  const [isMobileLayout, setIsMobileLayout] = useState<boolean>(false);
  const [state, dispatch] = useReducer(
    applicationReducer,
    applicationInitState
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < IS_MOBILE) {
        setIsMobileLayout(true);
      } else {
        setIsMobileLayout(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const value = useMemo(() => {
    return {
      state,
      isMobileLayout,
      dispatch,
    };
  }, [isMobileLayout, state]);

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
};

// Set default props for components of libraries
Modal.defaultProps = {
  centered: true,
  mousePosition: {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  },
  transitionName: "",
};

export default ApplicationProvider;

export const useApplication = () => useContext(ApplicationContext);
