import { Tooltip, Button } from "antd";
import { BaseButtonProps } from "antd/es/button/button";
import { TooltipPlacement } from "antd/es/tooltip";
import clsx from "clsx";
import { useCallback, useMemo } from "react";

interface ButtonProps extends BaseButtonProps {
  className?: string;
  icon?: React.ReactNode;
  title?: string;
  children?: React.ReactNode;
  typeButton?:
    | "success"
    | "warning"
    | "danger"
    | "primary"
    | "icon"
    | "link"
    | "outline"
    | "default"
    | "in-popconfirm";
  titleTooltip?: string;
  loading?: boolean;
  htmlType?: "button" | "reset" | "submit";
  onClick?: () => void;
  tooltipPlacement?: TooltipPlacement;
  headerButton?: boolean;
}

const CustomButton: React.FC<ButtonProps> = ({
  icon,
  title,
  children,
  type,
  typeButton = "default",
  titleTooltip = "",
  tooltipPlacement = "top",
  className,
  htmlType = "button",
  disabled,
  loading,
  size = "middle",
  ghost = false,
  headerButton = false,
  onClick,
}) => {
  const classBaseOnType = useMemo(() => {
    switch (typeButton) {
      case "success":
        return "bg-green1 text-white hover:!bg-green2 hover:!text-white";
      case "primary":
        return "bg-primary !text-white hover:!bg-primary hover:!text-white";
      case "warning":
        return "bg-yellow1 text-white hover:!bg-orange-300 hover:!text-white";
      case "danger":
        return "bg-redDanger text-white hover:!opacity-70 hover:!text-white hover:!bg-redDanger";
      case "icon":
        return "bg-redDanger bg-transparent shadow-none hover:!bg-gray4 hover:shadow-none hover:rounded-full focus-visible:outline-none";
      case "link":
        return "bg-transparent text-primary hover:!opacity-70 hover:!bg-transparent";
      case "outline":
        return "bg-white text-primary border !border-solid border-primary hover:!opacity-70";
      case "in-popconfirm":
        return "bg-white text-gray hover:!text-primary hover:!bg-gray4";
      default:
        return "bg-white text-gray border-[1px] border-solid border-gray5 hover:!border-primary hover:!text-primary";
    }
  }, [typeButton]);

  const renderButton = useCallback(() => {
    return (
      <Button
        className={clsx(
          "text-[12px] 2xl:text-[13px] !text-[#1E293B] font-bold leading-[23px] rounded-full py-0 cursor-pointer px-4 shadow-none hover:shadow-none",
          typeButton !== "icon" && typeButton !== "link" && !headerButton
            ? "h-10 min-w-10"
            : "h-8 w-fit",
          typeButton !== "default" ? "border-none" : "",
          disabled || loading ? "opacity-70 cursor-auto" : "",
          classBaseOnType,
          className
        )}
        htmlType={htmlType}
        onClick={onClick}
        type={type}
        disabled={disabled}
        loading={loading}
        icon={icon}
        size={size}
        ghost={ghost}
      >
        {children ? children : title ? title : null}
      </Button>
    );
  }, [
    typeButton,
    headerButton,
    disabled,
    loading,
    classBaseOnType,
    className,
    htmlType,
    onClick,
    type,
    icon,
    size,
    ghost,
    children,
    title,
  ]);

  if (titleTooltip) {
    return (
      <Tooltip title={titleTooltip} placement={tooltipPlacement}>
        {renderButton()}
      </Tooltip>
    );
  }

  return <>{renderButton()}</>;
};

export default CustomButton;
